import React from "react"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextLink,
} from "@kiwicom/orbit-components"
import List, { ListItem } from "@kiwicom/orbit-components/lib/List"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Annual Leave">
    <Hero title="Annual Leave" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard title="Annual Leave Allowance">
          <EmberCardSection>
            <Stack>
              <Text>
                You're entitled to 5.6 weeks of paid annual leave per year.
              </Text>
              <Text>
                For a full-time driver on a 40 hour contract, this works out as
                28 days of holiday with each day counting as eight hours of work
                for the purposes of pay.
              </Text>
              <Text>
                For a part-time driver, we'll still offer you 28 days of holiday
                but each day will be reduced by a pro-rata amount to reflect
                your contracted hours. For instance, if you're contracted for 20
                hours per week, each day of holiday used will count as four
                hours of work for the purposes of pay.
              </Text>
              <Text>
                If you don't use all your leave in a year, you can carry over up
                to five days into the next year. Any days carried over must be
                used by the end of March otherwise they'll be lost.
              </Text>
              <Text>
                You don't get public holidays off in addition to this so if you
                want to be off during a public holiday then make sure to request
                a day of annual leave. There may be a lot of demand to take time
                off during public holidays, so we suggest putting your request
                in well in advance to increase the chances it can be approved.
              </Text>
              <Text>
                Our holiday calendar runs from 1st January each year. If you
                start part way through the year, we'll calculate your holiday on
                a pro-rata basis from the date you start. We'll round up the
                number of days to the nearest day. The same applies if you leave
                part way through a year.
              </Text>
              <List>
                <ListItem>
                  Example 1: You start on 28 September. You have 13 weeks and 3
                  days until the end of the holiday year. That means you're
                  entitled to (13 + 3/7) / 52 * 28 = 7.23 days of holiday when
                  you start. This will be rounded up to the nearest day so
                  you'll actually get 8 days of holiday.
                </ListItem>
                <ListItem>
                  Example 2: You leave the company on 15 January, having been
                  working for the previous two years. You will have worked for 2
                  weeks and 1 day in the current holiday year. That means you're
                  entitled to 1.15 days of holiday for that year, which will be
                  rounded up to 2 days.
                </ListItem>
              </List>
              <Text>
                In addition, you can request extra unpaid leave. We generally
                happy to allow up to 10 unpaid days per year, subject to the
                regular approval criteria. However, we may be able to increase
                this. Contact us on chat if you're looking to book unpaid leave.
              </Text>
              <Text>
                If you have any questions about your remaining allowance, just
                ask the Operations Team in your chat room.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Requesting Leave">
          <EmberCardSection>
            <Stack>
              <Text>
                You can request days off through the{" "}
                <TextLink href="/account/driver/leave/">Leave Page</TextLink>.
                Requests must be made at least six weeks in advance.
              </Text>
              <Text>
                All requests are subject to approval. As a general rule:
              </Text>
              <List>
                <ListItem>
                  If fewer than 12% of the team (rounded up to the nearest
                  person) are on holiday at the time, your holiday will be
                  approved quickly. This works out as 3 people being off at a
                  time in a 25 person team.
                </ListItem>
                <ListItem>
                  If 12% - 24% of the team (rounded up to the nearest person)
                  are on holiday at the time, we'll try to find a way to cover
                  your holiday but it may take a bit longer and we may have to
                  say no if we are really struggling. This works out as 3 - 6
                  people being off at a time in a 25 person team.
                </ListItem>
                <ListItem>
                  If more than 24% of the team (rounded up to the nearest
                  person) are on holiday at a time, we're very unlikely to be
                  able to cover it. We're still happy to look at the request but
                  expect the answer to be a "no".
                </ListItem>
              </List>
              <Text>
                You should never make non-refundable bookings ahead of your
                holiday being approved.
              </Text>
              <Text>
                If you need a day off with less than six weeks notice, then
                you'll need to agree a swap with another driver and submit it
                through the{" "}
                <TextLink href="../../rota-adjustments/">
                  Shift Swap Form
                </TextLink>
                . You can also ask us if you can swap one of your shifts with an
                unassigned shift in the rota. If you can't find a swap that
                works, then unfortunately you'll need to work that day because
                we have committed to running a service.
              </Text>
              <Text>
                There are very exceptional reasons why we might approve holiday
                with less than six weeks notice on compassionate grounds. This
                would include things like attending a funeral of a loved one or
                a wedding of a close family member which had been arranged at
                the last minute. We'll look at it as humans so let us know if
                something like this applies.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Buffer Period">
          <EmberCardSection>
            <Stack>
              <Text>
                Annual leave days run from midnight to midnight. For instance,
                if you book annual leave on 27 June that would mean you are off
                from 00:00 on 27 June until 00:00 on 28 June.
              </Text>
              <Text>
                We'll also make sure that we assign shifts such that you are not
                working super late before your time off or starting super early
                right after it. Specifically:
              </Text>
              <List>
                <ListItem>
                  We'll make sure you finish work no later than 9pm the day
                  before
                </ListItem>
                <ListItem>
                  We'll make sure you start work no earlier than 6am the day
                  after
                </ListItem>
              </List>
              <Text>
                Note that this buffer period only applies where you've booked
                your leave within the required notice period. There may also be
                times where we are only able to approve your holiday by reducing
                the buffer period. We'll ask you if you'd still like to go ahead
                in these cases.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Days Off Within Your Annual Leave Booking">
          <EmberCardSection>
            <Stack>
              <Text>
                You'll be booking annual leave before you have visibility of the
                upcoming rota so you won't yet know which are your days off.
                This makes it impossible to pick your annual leave days to link
                up with days you'd be off anyway.
              </Text>
              <Text>
                To solve this, when you book a long-block of annual leave we
                only count some of these days against your annual leave
                allowance with the rest counted as regular days off. The number
                of days used is designed to mimic the number of days someone
                working a regular Monday - Friday worker would have to use.
              </Text>
              <Text>
                You should book a holiday for the full period and we'll
                automatically apply this policy. The table below details exactly
                how many days from your allowance each booking will use.
              </Text>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Total Days Off</TableCell>
                    <TableCell>Days of Annual Leave Used</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>2</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>3</TableCell>
                    <TableCell>2</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>4</TableCell>
                    <TableCell>2</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>5</TableCell>
                    <TableCell>3</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>6</TableCell>
                    <TableCell>4</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>7</TableCell>
                    <TableCell>5</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>8</TableCell>
                    <TableCell>5</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>9</TableCell>
                    <TableCell>5</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>10</TableCell>
                    <TableCell>6</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>11</TableCell>
                    <TableCell>7</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>12</TableCell>
                    <TableCell>8</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>13</TableCell>
                    <TableCell>9</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>14</TableCell>
                    <TableCell>10</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>15</TableCell>
                    <TableCell>10</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>16</TableCell>
                    <TableCell>10</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>17</TableCell>
                    <TableCell>11</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>18</TableCell>
                    <TableCell>12</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>19</TableCell>
                    <TableCell>13</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>20</TableCell>
                    <TableCell>14</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>21</TableCell>
                    <TableCell>15</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
